import isEmpty from 'lodash/isEmpty';

import {
  SEND_SETTINGS,
  SET_SETTINGS,
  SET_CURRENT_DATE,
  REMOVE_SETTINGS,
} from '~actions/action_types';
import {
  sendSetting,
  explodeSetting,
  getDefaultSettings,
  IsValidJSONString,
  removeSettingsForGoldenLayout,
  localStorageAsDefaultUserSettings,
  removeWidgetSettings,
} from '~utils/settings';
import { getHost } from '~utils/platform';
import { deepMerge } from '../utils';
import {
  widgetsNeedLoadUserSettings,
  widgetsWithBoxesSettings,
  widgetsWithSubWidgetsSettings,
  widgetsWithWidgetsSettings,
} from '../constants';

// Make this able to read old properties! as default value
// map only current user settings to redux store after login
const localSettings = localStorageAsDefaultUserSettings();

export default function settingsReducer(
  state = deepMerge(getDefaultSettings(), localSettings),
  action
) {
  const { type, payload = {} } = action;
  if (!payload) return state;

  const appName = window.APP_NAME || getHost().APP_NAME;
  const username = sessionStorage.getItem('global_username');
  const { domain = '', identifier } = payload;
  let { settingsObj = {} } = payload;

  try {
    // clean golden layout user settings when data from api
    if (type === SET_SETTINGS) {
      settingsObj = removeSettingsForGoldenLayout(domain, settingsObj);
    }

    // send sock msg to update db
    if (type === SEND_SETTINGS) {
      settingsObj = Object.entries(settingsObj).reduce((so, [k, v]) => {
        sendSetting(k, v, domain);

        return { ...so, ...explodeSetting(k, v) };
      }, {});
    }

    // update local storage and redux store
    // SET_SETTINGS => page load
    // SEND_SETTINGS => ui new changed
    if (type === SEND_SETTINGS || type === SET_SETTINGS) {
      let localStorageSettings;
      if (domain === 'flex_layout') {
        localStorageSettings = settingsObj[appName];
      } else {
        let existing = localStorage.getItem(`_${username}_${domain}`);
        existing = existing ? IsValidJSONString(existing) : {};
        localStorageSettings = deepMerge(existing, settingsObj);
      }

      localStorage.setItem(
        `_${username}_${domain}`,
        JSON.stringify(localStorageSettings)
      );

      let newSettings = {};

      if (domain === 'global_settings') {
        newSettings = settingsObj;
      } else if (domain === 'flex_layout') {
        newSettings = {
          [domain]: settingsObj[appName] || settingsObj.default,
        };
      } else if (
        [
          ...widgetsNeedLoadUserSettings,
          'slippage_widget',
          'rate_engine',
        ].includes(domain)
      ) {
        newSettings = { [domain]: deepMerge(state[domain], settingsObj) };
      }

      return { ...state, ...newSettings };
    }

    // current date update
    if (type === SET_CURRENT_DATE) {
      return {
        ...state,
        currentDate: action.payload,
      };
    }

    // clean user settings when widgets are removed
    if (type === REMOVE_SETTINGS) {
      const cleanSetting = {};
      const newIdentifier = Array.isArray(identifier)
        ? identifier
        : [identifier];

      // if it's not array, then widget might be customized
      if (
        widgetsWithBoxesSettings.includes(domain) &&
        !Array.isArray(state[domain]?.boxes)
      ) {
        cleanSetting.boxes = removeWidgetSettings(
          domain,
          'boxes',
          newIdentifier,
          state[domain]?.boxes
        );
      }

      // if it's not empty, then widget might be customized
      if (
        widgetsWithSubWidgetsSettings.includes(domain) &&
        !isEmpty(state[domain].sub_widgets)
      ) {
        cleanSetting.sub_widgets = removeWidgetSettings(
          domain,
          'sub_widgets',
          newIdentifier,
          state[domain]?.sub_widgets
        );
      }

      // if it's not empty, then widget might be customized
      if (
        widgetsWithWidgetsSettings.includes(domain) &&
        !isEmpty(state[domain].widgets)
      ) {
        cleanSetting.widgets = removeWidgetSettings(
          domain,
          'widgets',
          newIdentifier,
          state[domain]?.widgets
        );
      }

      localStorage?.setItem(
        `_${username}_${domain}`,
        JSON.stringify({ ...state[domain], ...cleanSetting })
      );

      return { ...state, [domain]: { ...state[domain], ...cleanSetting } };
    }

    return state;
  } catch (err) {
    return state;
  }
}
